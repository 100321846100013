<template>
  <el-config-provider :locale="elementLocale" :zIndex="3000">
    <DpApplicationContainer v-if="isAuth" :menuItems="menuItems">
      <ReminderSelectCompany/>
      <router-view/>
    </DpApplicationContainer>
  </el-config-provider>
</template>

<script setup>
import {onBeforeMount, computed} from "vue";

import {ReminderSelectCompany} from '@dutypay/settings-pages';
import {menuItems} from './pages.js';

import {useAuthStore, useCompanyStore, useEnvStore} from "@dutypay/store-modules";
const authStore = useAuthStore()
const envStore = useEnvStore()
const isAuth = computed(() => {
  return authStore.status === 'success'
})

import {useI18n} from "vue-i18n";
const { locale } = useI18n({useScope: 'global'})
import elementLocales from '@dutypay/components-library/src/locales/element-plus'
const elementLocale = computed(() => {
  return elementLocales[locale.value];
})

async function initApp() {
  envStore.setGlobalLoading()
  await envStore.fetchHosts()
  await authStore.tokenAuth()
  await envStore.getDebug()
  const companyStore = useCompanyStore()
  await companyStore.fetchCompanies()
  envStore.releaseGlobalLoading()
}

onBeforeMount(async () => {
  await initApp()
})
</script>

<style lang="scss">
@use "~element-plus/theme-chalk/src/index.scss" as *;
@import '~@dutypay/design-system/dist/dutypay-design-system.css';
$--color-primary: #5392bc;
@import '~@dutypay/design-system/src/scss_legacy/index.scss';
@import '~@dutypay/settings-pages/dist/dutypay-settings-pages.css';
</style>

<i18n>
{}
</i18n>
