<script setup>
import {computed} from 'vue'
import { useAuthStore, useCompanyStore } from '@dutypay/store-modules'
const authStore = useAuthStore()
const companyStore = useCompanyStore()

import { useI18n } from 'vue-i18n'
const { t } = useI18n()

const showContent = computed(() => {
  return authStore.hasUserName && companyStore.hasCompanies && companyStore.hasSelectedCompany;
})
</script>
<script>
  export default {
    // eslint-disable-next-line vue/multi-word-component-names
    name: "StartPage",
  }
</script>

<template>
  <div v-if="showContent">
    <h1>{{t('startPage.label')}}</h1>
      <router-view />
  </div>
</template>

<style scoped lang="scss">
</style>

<i18n>
  {}
</i18n>
