import {createI18n} from 'vue-i18n'
import translationValues from "./locales/translationValues.json";
import each from "lodash/each";
import assign from "lodash/assign";
import elementLocale from "@dutypay/components-library/src/locales/element-plus";
import {useJurisdictionSwitch} from "@dutypay/components-library/src/composables/jurisdictionSwitch";
const { loadLocale } = useJurisdictionSwitch()

function loadLocaleMessages () {
  const locales = require.context('./locales', true, /[A-Za-z0-9-_,\s]+\.json$/i)
  const messages = {}
  locales.keys().forEach(key => {
    const matched = key.match(/([A-Za-z0-9-_]+)\./i)
    if(matched[1].length === 5){
      if (matched && matched.length > 1) {
        const locale = matched[1]
        messages[locale] = locales(key)
      }
    }
  });

  each(translationValues, (value, key) => {
    messages[key] = assign(messages[key], value)
  })

  each(messages, (value, key) => {
    messages[key] = Object.assign(value, elementLocale[key]);
  })

  return messages;
}

export const i18n = createI18n({
  globalInjection: true,
  useScope: "global",
  legacy: false,
  locale: loadLocale(),
  fallbackLocale: process.env.VUE_APP_I18N_FALLBACK_LOCALE || 'en-US',
  messages: loadLocaleMessages(),
  fallbackWarn: false,
  silentTranslationWarn: true,
  silentFallbackWarn: true
});

